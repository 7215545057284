import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useBranchSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    switchStateList: [{
      false: '關閉', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }, {
      false: 'secondary', true: 'success',
    }],
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,

    syncObject,
    getMetaListData,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useBranchList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    timeRange,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useBranchSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: 'LOGO', key: 'icon', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'branch', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'introduce', sortable: true, searchable: true, select: true,
    },
    {
      label: '信箱驗證', key: 'mail_valid_switch', sortable: true, searchable: true, select: true,
    },
    {
      label: '電話驗證', key: 'phone_valid_switch', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員總數', key: 'customer_count', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const setBranchDelete = (...arg) => store.dispatch('admin-branch/setBranchDelete', ...arg)
  const setBranchCreate = (...arg) => store.dispatch('admin-branch/setBranchCreate', ...arg)
  const setBranchUpdate = (...arg) => store.dispatch('admin-branch/setBranchUpdate', ...arg)

  const blankbranchInfo = {
    id: null,
    name: null,
    branch: null,
    domain: null,
    icon: null,
    introduce: null,
    mail_valid_switch: false,
    phone_valid_switch: false,
    customer_count: 0,
    created_at: null,
  }

  const getBranchListData = (ctx, callback) => {
    store.dispatch('admin-branch/getBranchList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankbranchInfo, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    setBranchDelete,
    setBranchCreate,
    setBranchUpdate,
    getBranchListData,
    useHttpCodeAlert,
  }
}

export const useBranchView = () => {
  const {
    syncObject,
  } = useBranchSetting()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const isBusy = ref(false)
  const paymentOptions = ref([])

  const blankbranchInfo = {
    id: null,
    SEO: null,
    banner: null,
    name: null,
    branch: null,
    prefix: null,
    domain: null,
    content1: null,
    content2: null,
    content3: null,
    icon: null,
    introduce: null,
    lang: 'zh-tw',
    mail_api: null,
    mail_valid_switch: false,
    phone_valid_switch: false,
    payment: [],
    plugs: null,
    sms_api: null,
    customer_count: 0,
    created_at: null,
    updated_at: null,
  }

  const blnkPaymentData = {
    id: null,
    name: null,
    third_party_id: null,
    type: null,
    state: false,
    daily: 0,
    daily_total: 0,
    high: 0,
    low: 0,
    monthly: 0,
    monthly_total: 0,
  }

  const blankPaymentType = {
    id: null,
    name: null,
    provider: null,
  }

  const blankMailApiInfo = {
    id: null,
    name: null,
    content: null,
  }

  const blankChartData = {
    series: [65],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#4683bb'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#aab3bb',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const setBranchImageUpload = (...arg) => store.dispatch('admin-branch/setBranchImageUpload', ...arg)
  const setBranchImageDelete = (...arg) => store.dispatch('admin-branch/setBranchImageDelete', ...arg)

  const setBranchUpdate = (branchData, callback) => {
    store.dispatch('admin-branch/setBranchUpdate', { ...branchData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankbranchInfo, data),
          mail_api_info: syncObject(blankMailApiInfo, data.mail_api_info ? data.mail_api_info : {}),
        }
        resolveData.payment = data.payment_info.map(item => item.id) || []
        resolveData.payment_info = data.payment_info.map(item => {
          const resolve = {
            ...syncObject(blnkPaymentData, item),
            third_party_payment: syncObject(blankPaymentType, item.third_party_payment ? item.third_party_payment : {}),
          }
          return resolve
        }) || []

        store.commit('admin-branch/UPDATE_BRANCH_INFO_STATE', resolveData)
        callback(true)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        callback(false)
      })
  }

  const getBranchInfoData = (updateData, callback) => {
    store.dispatch('admin-branch/getBranchData', { ...updateData })
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankbranchInfo, data),
          mail_api_info: syncObject(blankMailApiInfo, data.mail_api_info ? data.mail_api_info : {}),
        }
        resolveData.payment = data.payment_info.map(item => item.id) || []
        resolveData.payment_info = data.payment_info.map(item => {
          const resolve = {
            ...syncObject(blnkPaymentData, item),
            third_party_payment: syncObject(blankPaymentType, item.third_party_payment ? item.third_party_payment : {}),
          }
          return resolve
        }) || []
        store.commit('admin-branch/UPDATE_BRANCH_INFO_STATE', resolveData)
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    syncObject,
    isBusy,
    paymentOptions,
    blankChartData,
    blankMailApiInfo,
    blnkPaymentData,
    blankPaymentType,
    useAlertToast,
    useHttpCodeAlert,
    getBranchInfoData,
    setBranchImageUpload,
    setBranchImageDelete,
    setBranchUpdate,
  }
}
